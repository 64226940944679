// @ts-nocheck
import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Header from "../components/Header";
import TopBG from "../images/header.svg";
import Pricing from "../components/Pricing";
import { Trans } from "react-i18next";
import { Button, Box, Title, Text } from "@logrock/pebbles";
import Footer from "../components/Footer";
import Features from "../components/Features";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { up } from "styled-breakpoints";
import Integrations from "../components/Integrations/Integrations";

const SiteTitle = styled(Title)`
  font-size: 32px;
  line-height: 38px;
  text-align: start;

  ${up("desktop")} {
    font-size: 48px;
    line-height: 54px;
  }
`;

const SiteSubtitle = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  text-align: start;

  ${up("desktop")} {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
  }
`;

const StyledBox = styled(Box)`
  .react-p5 {
    width: 100%;
    height: 100%;

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const IndexPage: FC = () => {
  const isDesktop = useBreakpoint(up("desktop"));

  return (
    <>
      <Helmet>
        <title>
          {
            // eslint-disable-next-line i18next/no-literal-string
          }
          LogRock - The only FREE compliance software
        </title>
      </Helmet>
      <Header />
      <Box spaceAfter="huge">
        <Box
          width="12/12"
          style={{ maxWidth: 1280 }}
          display="grid"
          gridColumns={isDesktop ? "3fr 4fr" : "1fr"}
          align="stretch"
        >
          <Box align="start" rowGap="xbig" padding={isDesktop ? "zero" : "big"}>
            <SiteTitle type="displayLarge" weight="bolder">
              <Trans>Driver Recruiting + DOT compliance software.</Trans>
            </SiteTitle>

            <SiteSubtitle type="displaySmall" shade="600">
              <Text
                color="primary"
                shade="500"
                as="span"
                style={{
                  fontSize: isDesktop ? 40 : 24,
                  lineHeight: isDesktop ? "48px" : "32px",
                }}
              >
                <Trans>Free</Trans>
              </Text>{" "}
              <Trans>for fleets under 50 power units.</Trans>
            </SiteSubtitle>

            <Button
              style={
                isDesktop
                  ? { width: "unset" }
                  : { width: "100%", justifyContent: "center" }
              }
              buttonSize="large"
              onClick={() =>
                (window.location.href = "https://app.logrock.com/onboarding")
              }
              spaceAfter={!isDesktop ? "lg" : "zero"}
            >
              <Trans>Sign up</Trans>
            </Button>
          </Box>

          <TopBG
            width={isDesktop ? 831 : "100%"}
            height={isDesktop ? 541 : 250}
          />
        </Box>
      </Box>

      {/* <Box
        direction="column"
        align="center"
        spaceAfter={isDesktop ? "huge" : "zero"}
      >
        <Box spaceAfter="xxlg" />
        <Box
          style={{ maxWidth: 1280 }}
          width="12/12"
          padding={isDesktop ? "zero" : "big"}
        >
          <Box
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              paddingTop: "56.25%",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/Ahqvh5f8Kes"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Box>
        </Box>
      </Box> */}

      <Box id="products">
        <Features />
      </Box>

      <Box
        id="pricing"
        backgroundColor="primary"
        backgroundShade="300"
        spaceAfter={isDesktop ? "lg" : "big"}
      >
        <Pricing />
      </Box>

      <Box
        width="12/12"
        style={{ maxWidth: 1280, margin: "0 auto" }}
        spaceAfter="xxxhuge"
      >
        {isDesktop && (
          <Box
            id="integrations"
            display="grid"
            gridColumns="4fr 6fr"
            spaceAfter="xxlg"
            align="center"
          >
            <Box align="start" style={{ textAlign: "start" }}>
              <SiteTitle spaceAfter="lg" weight="boldest">
                <Trans>
                  Connect your tools with{" "}
                  <Text
                    color="primary"
                    shade="500"
                    as="span"
                    style={{
                      fontSize: isDesktop ? 48 : 32,
                      lineHeight: isDesktop ? "54px" : "38px",
                    }}
                    weight="boldest"
                  >
                    <Trans>LogRock</Trans>
                  </Text>
                </Trans>
              </SiteTitle>

              <Text spaceAfter="md" style={{ textAlign: "start" }}>
                <Trans>
                  LogRock connects directly to over 12 of the most popular ELDs.
                </Trans>
              </Text>

              <Text spaceAfter="md" style={{ textAlign: "start" }}>
                <Trans>
                  We also pull data for you from your FMCSA accounts.
                </Trans>
              </Text>

              <Text spaceAfter="lg" style={{ textAlign: "start" }}>
                <Trans>
                  We even link up with your fuel card so we can audit hours of
                  service and catch potential violations.
                </Trans>
              </Text>

              <Button
                buttonStyle="secondary"
                buttonSize="large"
                onClick={() =>
                  (window.location.href = "https://app.logrock.com/onboarding")
                }
              >
                <Trans>Sign up</Trans>
              </Button>
            </Box>

            <StyledBox>
              <Integrations />
            </StyledBox>
          </Box>
        )}

        {!isDesktop && (
          <Box id="integrations" spaceAfter="xxlg" align="start" padding="big">
            <Box align="start" style={{ textAlign: "start" }}>
              <SiteTitle spaceAfter="lg" weight="boldest">
                <Trans>
                  Connect your tools with{" "}
                  <Text
                    color="primary"
                    shade="500"
                    as="span"
                    style={{
                      fontSize: isDesktop ? 48 : 32,
                      lineHeight: isDesktop ? "54px" : "38px",
                    }}
                    weight="boldest"
                  >
                    <Trans>LogRock</Trans>
                  </Text>
                </Trans>
              </SiteTitle>

              <StyledBox>
                <Integrations />
              </StyledBox>

              <Text spaceAfter="md" style={{ textAlign: "start" }}>
                <Trans>
                  LogRock connects directly to over 12 of the most popular ELDs.
                </Trans>
              </Text>

              <Text spaceAfter="md" style={{ textAlign: "start" }}>
                <Trans>
                  We also pull data for you from your FMCSA accounts.
                </Trans>
              </Text>

              <Text spaceAfter="md" style={{ textAlign: "start" }}>
                <Trans>
                  We even link up with your fuel card so we can audit hours of
                  service and catch potential violations.
                </Trans>
              </Text>

              <Button
                buttonStyle="secondary"
                buttonSize="large"
                onClick={() =>
                  (window.location.href = "https://app.logrock.com/onboarding")
                }
                style={{ width: "100%", justifyContent: "center" }}
              >
                <Trans>Sign up</Trans>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Footer />
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          data
          ns
          language
        }
      }
    }
  }
`;

export default IndexPage;
