import { Box, Title, Text, Button } from "@logrock/pebbles";
import React from "react";
import { Trans } from "react-i18next";
import {
  BsCardChecklist,
  BsChevronLeft,
  BsChevronRight,
  BsCircleFill,
  BsPersonPlus,
} from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";

const StyledTitle = styled(Title)`
  font-size: 32px;
  line-height: 38px;

  ${up("desktop")} {
    font-size: 58px;
    line-height: 66px;
  }
`;

const StyledText = styled(Text)`
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  ${up("desktop")} {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`;

const SAFETY_DOT = "SAFETY_DOT";
const DRIVER_RECRUITING = "DRIVER_RECRUITING";

const DOT_FEATURES = [
  {
    name: "Driver Files",
    description:
      "All your drivers have individual profiles with the most relevant professional information and all the DOT-required files, so you can store and manage them on LogRock.",
    image: "/images/driver-files.png",
  },
  {
    name: "Equipment Files",
    description:
      "Keep all your Equipment files and information in one single place: annual vehicle inspections, services invoices, tire sizes, and others.",
    image: "/images/equipment-files.png",
  },
  {
    name: "Tasks",
    description:
      "Be alerted every time a document is missing, expired, or is about to expire, so you can reduce the number of violations you get.",
    image: "/images/tasks.png",
  },
  {
    name: "Risk Radar",
    description:
      "We integrate with your FMCSA Portal data to give you a 360-degree view of your safety and compliance program. Have access to insights such as: most recent violations, Risk of being inspected or audited, real CSA/SMS Scores, and trending on Violations, Inspections, and Out of Service Rates.",
    image: "/images/risk-radar.png",
  },
  {
    name: "Driver Monitor",
    description:
      "We combine your FMCSA and your ELD Data to analyze how your drivers are performing safety and compliance-wise. All drivers are ranked according to the risk they expose your company to, so you can prioritize them for interventions or for bonuses.",
    image: "/images/driver-monitor.png",
  },
];

const RECRUITING_FEATURES = [
  {
    name: "Electronic Application",
    description:
      "Streamline your driver hiring processe with our paperless applications, from collecting DOT-required information to obtaining electronic signatures for all necessary consents. The proccess is efficient and convenient, and drivers can complete it from any device.",
    image: "/images/electronic-application.png",
  },
  {
    name: "Candidate Management",
    description:
      "Stay on top of all new leads and applicants in real-time with our tracking system. Easily monitor how they found your company and identify any missing requirements needed to meet DOT regulations for hiring.",
    image: "/images/candidate-management.png",
  },
  {
    name: "Hiring Steps",
    description:
      "Hiring steps are easy to understand activities to hire your CDL and non-CDL drivers in compliance with the FMCSA. We help you to keep all DQ files organized so can you focus your time on finding the best drivers for your team.",
    image: "/images/hiring-steps.png",
  },
  {
    name: "Email Notifications",
    description:
      "Timing is key for hiring. Be notified every time you get a new hiring lead or a driver submitted an application so you can reduce your response time. Also, send timely reminders to help your drivers finish their applications.",
    image: "/images/email-notifications.png",
  },
  {
    name: "DOT Compliant PDF",
    description:
      "Driver Application Files made easy with our automatically generated PDF. All information your applicants provided and the digitally signed consents in one single document.",
    image: "/images/dot-compliant-pdf.png",
  },
];

const CarouselWrapper = styled(Box)`
  position: relative;

  .carousel-root {
    width: 100%;
  }

  .description {
    transition: 0.5s;

    opacity: 0;
  }

  .selected {
    .description {
      opacity: 1;
    }
  }
`;

export default function Features() {
  const [activeTab, setActiveTab] = React.useState(SAFETY_DOT);

  const currentFeatures =
    activeTab === SAFETY_DOT ? DOT_FEATURES : RECRUITING_FEATURES;

  const [activeFeature, setActiveFeature] = React.useState(0);

  const isDesktop = useBreakpoint(up("desktop"));

  return (
    <Box spaceAfter="xxxhuge" padding={isDesktop ? "xlg" : "big"}>
      <StyledTitle weight="boldest">
        <Trans>One platform.</Trans>
      </StyledTitle>
      <StyledTitle
        weight="boldest"
        color="primary"
        shade="600"
        spaceAfter="xbig"
      >
        <Trans>Many superpowers.</Trans>
      </StyledTitle>

      <StyledText type="paragraphLarge" spaceAfter={isDesktop ? "xlg" : "big"}>
        <Trans>LogRock is free for companies under 50 power units.</Trans>
      </StyledText>

      <Box
        justify="center"
        direction="row"
        colGap="md"
        rowGap="md"
        spaceAfter={isDesktop ? "xxxlg" : "xbig"}
      >
        <Button
          type="button"
          buttonStyle={activeTab === SAFETY_DOT ? "secondary" : "outlined"}
          buttonSize={isDesktop ? "large" : "small"}
          onClick={() => setActiveTab(SAFETY_DOT)}
          startIcon={
            <BsCardChecklist
              size={isDesktop ? 20 : undefined}
              style={{ marginRight: 8, marginTop: -2 }}
            />
          }
          variant="rounded"
        >
          <Trans>Safety + DOT {isDesktop ? "Compliance" : null}</Trans>
        </Button>

        <Button
          type="button"
          buttonStyle={activeTab !== SAFETY_DOT ? "secondary" : "outlined"}
          buttonSize={isDesktop ? "large" : "small"}
          onClick={() => setActiveTab(DRIVER_RECRUITING)}
          startIcon={
            <BsPersonPlus
              size={isDesktop ? 20 : undefined}
              style={{ marginRight: 8, marginTop: -2 }}
            />
          }
          variant="rounded"
        >
          <Trans>Driver Recruiting</Trans>
        </Button>
      </Box>

      <Box
        display="grid"
        gridColumns={isDesktop ? "270px 1fr" : "1fr"}
        colGap="huge"
        style={{ maxWidth: 1280, position: "relative" }}
        align="start"
      >
        {isDesktop && (
          <Box>
            {currentFeatures.map((feature, index) => (
              <Box
                key={feature.name}
                direction="row"
                align="center"
                spaceAfter="md"
                backgroundColor={activeFeature === index ? "neutral" : "white"}
                backgroundShade="100"
                borderColor="neutral"
                borderShade="100"
                borderWidth="xxxsm"
                onClick={() => setActiveFeature(index)}
                padding="md"
                borderRadius="sm"
              >
                <BsCircleFill
                  size={8}
                  color={activeFeature === index ? "#27E06B" : "#D1D5DB"}
                  style={{ marginRight: 8, marginTop: -2 }}
                />
                <Text type="paragraphMedium" weight="bold">
                  {feature.name}
                </Text>
              </Box>
            ))}
          </Box>
        )}

        <Box
          borderRadius="xbig"
          borderShade="200"
          borderColor="neutral"
          borderWidth="xxxxsm"
          style={{
            overflow: "hidden",
            outlineColor: "#FFFFFFAA",
            outlineWidth: 16,
            outlineStyle: "solid",
          }}
          direction="column"
          align="stretch"
          shadow="xxLarge"
        >
          <Box
            padding="big"
            backgroundColor="primary"
            backgroundShade="200"
            align="center"
            justify="start"
            direction="row"
            colGap="sm"
          >
            <BsCircleFill color="#F9FAFB" />
            <BsCircleFill color="#F9FAFB" />
            <BsCircleFill color="#F9FAFB" />
          </Box>

          <CarouselWrapper
            width="12/12"
            backgroundColor="white"
            backgroundShade="100"
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <svg
                width={isDesktop ? "381" : "200"}
                height={isDesktop ? "381" : "200"}
                viewBox="0 0 381 381"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "calc(50vh - 56px - 24px - 24px)",
                  height: "calc(50vh - 56px - 24px - 24px)",
                  maxWidth: 381,
                  maxHeight: 381,
                }}
              >
                <circle
                  cx="190.5"
                  cy="190.5"
                  r="190.5"
                  fill="#F9FAFB"
                  fillOpacity="0.6"
                />
              </svg>
            </div>

            <Carousel
              centerMode={isDesktop}
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              centerSlidePercentage={60}
              width="100%"
              renderArrowNext={() => null}
              renderArrowPrev={() => null}
              selectedItem={activeFeature}
              showStatus={false}
            >
              {currentFeatures.map((feature, index) => (
                <Box key={feature.name}>
                  <Box
                    style={{
                      width: "100%",
                      height: isDesktop ? 150 : 200,
                      paddingTop: 32,
                    }}
                    className="description"
                    noWrap
                    padding={!isDesktop ? "md" : undefined}
                  >
                    <Text
                      weight="bold"
                      spaceAfter="md"
                      type="paragraphLarge"
                      shade="900"
                    >
                      {feature.name}
                    </Text>
                    <Text type="paragraphMedium" shade="700">
                      {feature.description}
                    </Text>
                  </Box>

                  <div
                    style={{
                      height: isDesktop
                        ? "calc(60vh - 56px - 24px - 24px)"
                        : 300,
                      width: "100%",
                      maxHeight: 500,
                      objectPosition: "center",
                      objectFit: "contain",
                    }}
                  >
                    <img
                      src={feature.image}
                      alt={feature.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectPosition: "center",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Box>
              ))}
            </Carousel>
          </CarouselWrapper>
        </Box>

        {!isDesktop && (
          <Box direction="row" padding="xlg" justify="center" colGap="md">
            <Button
              iconOnly
              startIcon={<BsChevronLeft />}
              buttonStyle="outlined"
              disabled={activeFeature === 0}
              onClick={() =>
                setActiveFeature((activeFeature) =>
                  activeFeature > 0 ? activeFeature - 1 : activeFeature
                )
              }
            ></Button>
            <Button
              iconOnly
              startIcon={<BsChevronRight />}
              buttonStyle="outlined"
              disabled={activeFeature === currentFeatures.length - 1}
              onClick={() =>
                setActiveFeature((activeFeature) =>
                  activeFeature < currentFeatures.length - 1
                    ? activeFeature + 1
                    : activeFeature
                )
              }
            ></Button>
          </Box>
        )}

        <div
          style={{
            position: "absolute",
            bottom: -150,
            right: isDesktop ? -100 : 0,
            zIndex: -1,
          }}
        >
          <svg
            style={{
              width: 589,
              height: 568,
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            viewBox="0 0 589 568"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M474.871 23.17C524.344 57.1277 538.138 121.508 556.725 178.579C575.267 235.512 601.811 295.5 581.962 351.99C562.28 408.007 501.295 433.543 454.369 469.896C405.768 507.546 364.769 564.082 303.412 567.78C241.162 571.531 188.492 526.618 138.806 488.913C90.2787 452.086 44.3758 410.882 21.9877 354.212C-1.32712 295.196 -8.52599 228.681 12.3218 168.749C32.9031 109.583 79.7614 62.8364 134.146 31.7831C184.568 2.99296 243.649 4.99092 301.687 3.52381C361.223 2.0188 425.763 -10.5375 474.871 23.17Z"
              fill="#B9B9F9"
              fillOpacity="0.1"
            />
          </svg>
        </div>
      </Box>
    </Box>
  );
}
