import React, { useCallback, useRef } from "react";
import p5Types from "p5";
import loadable from "@loadable/component";

const Sketch = loadable(() => import("react-p5"));

interface ComponentProps {
  // Your component props
}

interface IntegrationCircle {
  x: number;
  y: number;
  diameter: number;
  xSpeed: number;
  ySpeed: number;
  opacity: number;
}

const canvasWidth = 800;
const canvasHeight = 800;

const numberOfCircles = 10;

const initializeCircle = (p5: p5Types, fullRandom?: boolean) => {
  const shouldSpawnOnXAxis = p5.random(0, 1) > 0.5;

  let x, y;

  if (fullRandom) {
    x = p5.random(0, p5.width);
    y = p5.random(0, p5.height);
  } else {
    if (shouldSpawnOnXAxis) {
      x = p5.random(0, 1) > 0.5 ? 0 : canvasWidth;
      y = p5.random(0, p5.height);
    } else {
      x = p5.random(0, p5.width);
      y = p5.random(0, 1) > 0.5 ? 0 : canvasHeight;
    }
  }

  const diameter = p5.random(100, 120);

  // if x is 0, xSpeed is positive, else negative
  const xSpeed = x === 0 ? p5.random(0.5, 1) : p5.random(-1, -0.5);
  // if y is 0, ySpeed is positive, else negative
  const ySpeed = y === 0 ? p5.random(0.5, 1) : p5.random(-1, -0.5);

  const opacity = 0;

  return { x, y, diameter, xSpeed, ySpeed, opacity };
};

const logoPaths = [
  "/logos/Brand-1.png",
  "/logos/Brand-2.png",
  "/logos/Brand-3.png",
  "/logos/Brand-4.png",
  "/logos/Brand-5.png",
  "/logos/Brand-6.png",
  "/logos/Brand-7.png",
  "/logos/Brand-8.png",
  "/logos/Brand-9.png",
  "/logos/Brand.png",
];

const Integrations: React.FC<ComponentProps> = (props: ComponentProps) => {
  const integrationCircles = useRef([] as IntegrationCircle[]);
  const logos = useRef([] as p5Types.Image[]);
  const logRockLogo = useRef<p5Types.Image>();
  const pgs = useRef([] as p5Types.Graphics[]);
  const logRockPg = useRef<p5Types.Graphics>();
  const centralCircle = useRef<IntegrationCircle>();
  const initialTime = useRef(0);

  const preload = (p5: p5Types) => {
    // load logos
    for (let i = 0; i < logoPaths.length; i++) {
      p5.loadImage(logoPaths[i], (img) => {
        logos.current[i] = img;
      });
    }

    // load central circle logo
    p5.loadImage("/logos/LogRock.svg", (img) => {
      logRockLogo.current = img;
    });
  };

  // See annotations in JS for more information
  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(canvasWidth, canvasHeight).parent(canvasParentRef);

    const intCircles = [] as IntegrationCircle[];

    // initialize 10 integration circles with random positions and speeds
    for (let i = 0; i < numberOfCircles; i++) {
      intCircles.push(initializeCircle(p5, true));
    }

    // create graphics for each logo
    for (let i = 0; i < logos.current.length; i++) {
      const logo = logos.current[i];
      const pg = p5.createGraphics(logo.width, logo.height);
      pg.image(logo, 0, 0, logo.width, logo.height);
      pgs.current.push(pg);
    }

    // create graphics for central circle
    const pg = p5.createGraphics(
      logRockLogo.current?.width || 0,
      logRockLogo.current?.height || 0
    );
    pg.image(
      logRockLogo.current || p5.createGraphics(0, 0),
      0,
      0,
      logRockLogo.current?.width || 0,
      logRockLogo.current?.height || 0
    );
    logRockPg.current = pg;

    integrationCircles.current = intCircles;

    initialTime.current = p5.millis();

    centralCircle.current = {
      x: canvasWidth / 2,
      y: canvasHeight / 2,
      diameter: 150,
      opacity: 255,
      xSpeed: 0,
      ySpeed: 0,
    };
  };

  const draw = useCallback(
    (p5: p5Types) => {
      p5.clear();

      // gravitate central circle towards mouse
      if (centralCircle.current) {
        // if mouse is outside of canvas, gravitate to canvas center
        if (
          p5.mouseX < 0 ||
          p5.mouseX > canvasWidth ||
          p5.mouseY < 0 ||
          p5.mouseY > canvasHeight
        ) {
          const dx = p5.width / 2 - centralCircle.current.x;
          const dy = p5.height / 2 - centralCircle.current.y;

          const distance = p5.sqrt(dx * dx + dy * dy);

          const force = 0.02;

          const xForce = (dx / distance) * force;
          const yForce = (dy / distance) * force;

          centralCircle.current.xSpeed += xForce;
          centralCircle.current.xSpeed *= 0.99;

          centralCircle.current.ySpeed += yForce;
          centralCircle.current.ySpeed *= 0.99;

          // update central circle position
          centralCircle.current.x += centralCircle.current.xSpeed;
          centralCircle.current.y += centralCircle.current.ySpeed;
        } else {
          const dx = p5.mouseX - centralCircle.current.x;
          const dy = p5.mouseY - centralCircle.current.y;

          const distance = p5.sqrt(dx * dx + dy * dy);

          const force = 0.02;

          const xForce = (dx / distance) * force;
          const yForce = (dy / distance) * force;

          centralCircle.current.xSpeed += xForce;
          centralCircle.current.xSpeed *= 0.99;

          centralCircle.current.ySpeed += yForce;
          centralCircle.current.ySpeed *= 0.99;

          // update central circle position
          centralCircle.current.x += centralCircle.current.xSpeed;
          centralCircle.current.y += centralCircle.current.ySpeed;
        }
      }

      // draw circles no stroke only fill and scale opacity to distance to borders
      integrationCircles.current.forEach((circle, index) => {
        // update circle position
        circle.x += circle.xSpeed;
        circle.y += circle.ySpeed;

        // gravitate circle towards central circle
        if (centralCircle.current) {
          const distanceToCentralCircle = p5.dist(
            circle.x,
            circle.y,
            centralCircle.current?.x,
            centralCircle.current?.y
          );

          if (distanceToCentralCircle < 200) {
            const dx = centralCircle.current.x - circle.x;
            const dy = centralCircle.current.y - circle.y;

            const distance = p5.sqrt(dx * dx + dy * dy);

            const force = 0.02;

            const xForce = (dx / distance / 2) * force;
            const yForce = (dy / distance / 2) * force;

            circle.xSpeed += xForce;
            circle.xSpeed *= 0.99;

            circle.ySpeed += yForce;
            circle.ySpeed *= 0.99;
          }
        }

        // find point in circle closest to center
        if (centralCircle.current) {
          const dx = centralCircle.current?.x - circle.x;
          const dy = centralCircle.current?.y - circle.y;

          const distance = p5.sqrt(dx * dx + dy * dy);

          const x = circle.x + (dx / distance / 2) * circle.diameter;
          const y = circle.y + (dy / distance / 2) * circle.diameter;

          // draw a line from x y to center
          p5.strokeWeight(2);
          p5.stroke(229, 231, 235, circle?.opacity - 50);
          p5.line(x, y, centralCircle.current?.x, centralCircle.current?.y);
        }

        // scale opacity from 255 to 0 based on distance to closest border
        const distanceToClosestBorder = p5.min(
          circle.x,
          p5.min(
            circle.y,
            p5.min(canvasWidth - circle.x, canvasHeight - circle.y)
          )
        );

        circle.opacity = p5.map(
          distanceToClosestBorder,
          circle.diameter,
          circle.diameter * 2,
          0,
          255
        );

        p5.strokeWeight(2);
        p5.stroke(229, 231, 235, circle?.opacity);
        p5.fill(249, 250, 251, circle?.opacity);
        p5.circle(circle?.x, circle?.y, circle?.diameter);

        (p5.drawingContext as CanvasRenderingContext2D).globalAlpha =
          circle.opacity <= 0 ? 0 : circle.opacity / 255;

        // draw logo
        if (pgs.current[index]) {
          const pg = pgs.current[index];
          pg.tint(255, circle.opacity);
          p5.imageMode(p5.CENTER);
          p5.image(
            pg,
            circle.x,
            circle.y,
            circle.diameter * 0.6,
            (circle.diameter * 0.6 * logos.current[index].height) /
              logos.current[index].width
          );
        }

        (p5.drawingContext as CanvasRenderingContext2D).globalAlpha = 1;

        // if circle is outside of canvas, re initialize it
        if (
          circle.x < 0 ||
          circle.x > canvasWidth ||
          circle.y < 0 ||
          circle.y > canvasHeight
        ) {
          const newCircle = initializeCircle(p5);
          integrationCircles.current.splice(
            integrationCircles.current.indexOf(circle),
            1,
            newCircle
          );
        }

        // if circle is contained in central circle, re initialize it
        if (centralCircle.current) {
          const d = p5.sqrt(
            (circle.x - centralCircle.current.x) ** 2 +
              (circle.y - centralCircle.current.y) ** 2
          );

          if (centralCircle.current.diameter / 2 > d + circle.diameter / 2) {
            const newCircle = initializeCircle(p5);
            integrationCircles.current[
              integrationCircles.current.indexOf(circle)
            ] = newCircle;
          }
        }
      });

      // draw central circle no stroke only fill
      if (centralCircle.current) {
        p5.strokeWeight(2);
        p5.stroke(229, 231, 235, centralCircle.current?.opacity);
        p5.fill(249, 250, 251, centralCircle.current?.opacity);
        p5.circle(
          centralCircle.current?.x,
          centralCircle.current?.y,
          centralCircle.current?.diameter
        );

        // draw logrock logo
        const pg = logRockPg.current;
        p5.imageMode(p5.CENTER);
        p5.image(
          pg,
          centralCircle.current.x,
          centralCircle.current.y,
          centralCircle.current.diameter * 0.8,
          (centralCircle.current.diameter * 0.8 * logRockLogo.current.height) /
            logRockLogo.current.width
        );
      }
    },
    [initialTime, centralCircle]
  );

  return <Sketch setup={setup} draw={draw} preload={preload} />;
};

export default Integrations;
