/* eslint-disable react/no-unescaped-entities */
import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Button, Title, Box } from "@logrock/pebbles";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { up } from "styled-breakpoints";

const StyledTitle = styled(Title)`
  font-size: 32px;
  line-height: 38px;

  ${up("desktop")} {
    font-size: 56px;
    line-height: 64px;
  }
`;

export const Pricing: FC = () => {
  const isDesktop = useBreakpoint(up("desktop"));

  return (
    <Box>
      <Box
        padding={isDesktop ? "xxlg" : "lg"}
        width={isDesktop ? "6/12" : "12/12"}
        style={{ textAlign: "center" }}
      >
        <StyledTitle weight="boldest">
          <Trans>Sign Up For Free</Trans>
        </StyledTitle>

        <Title
          type={isDesktop ? "headingH3" : "headingH5"}
          weight="normal"
          shade="700"
          spaceAfter={"xxbig"}
        >
          <Trans>No signup fee. No contract. No extra charges.</Trans>
        </Title>

        <Button buttonStyle="secondary" buttonSize="large">
          <Trans>Sign up</Trans>
        </Button>
      </Box>
    </Box>
  );
};
